html, body {
  margin: 0 !important;
  padding: 0 !important;
  width: 100vw;
  overflow-x: hidden;
  color: black;
  font-family: 'Agdasima', sans-serif;
  font-size: 125%;
}


.main{
  min-height: 100vh;
  min-width: 100vw !important;
}

a{
  text-decoration: none !important;
}

h1, h2, h3, h4, .navbar-brand{
  font-family: 'Bebas Neue', sans-serif;
}


.navbar{
  background: rgb(21,61,167) !important ;
  min-height: 5vh;
  width: 100vw;
}

nav a{
  color: white !important;
}

.nav-link{
  font-size: 80%;
}

.fa-bars{
  color: white !important;
}

.nav-link:hover, .navbar-brand:hover{
  color: gold !important
}

.dropdown-item:hover{
  color: gold !important;
  background: rgb(21,61,167) !important;
}

@media (min-width: 992px) {
  .dropdown:hover .nav-dropdown{
    display: block;
    margin-top: 0;
  } 
}

.dropdown-item{
  color: rgb(21,61,167) !important;
}

.btn{
  background: gold;
  color: black;
}

.btn:hover{
  background: rgb(196, 166, 0);
}

/* --------------------HOMEPAGE---------------------------- */

.home-section-heading{
  font-size: 200%;
}

.hero-container {
  position: relative;
  height: 60vh;
  overflow: hidden;
}

.banner {
  opacity: 0.2;
  object-fit: cover;
  padding: 0;
}

.hero-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  background: rgba(0, 0, 0, 0);
  top: 0;
  bottom: 0;
  left: 40vw;
  width: 30vw;
  right: 0;
  height: 100%;
  margin: auto;
  padding: 0;
}

.hero-image-div{
  position: absolute;
  background: rgba(0, 0, 0, 0);
  top: 5vh;
  width: 30vw;
  bottom: 0;
  padding: 0;
  left: 10%;
}

.hero-image{
  width: 100%;
}

.hero-logo{
  width: 70%;
}

.hero-title {
  font-size: 3vw;
  width: 150%;
  color: black;
  background: rgba(0, 0, 0, 0);
}

.hero-subtitle {
  font-size: 2vw;
  width: 150%;
  color: black;
}

#nav-icons{
  min-height: 75vh;
}

.nav-icons{
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: rgb(21,61,167);
}

.nav-icon-div{
  text-align: center;
  color: white;
}

.nav-icon{
  display: flex;
  margin: auto;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  font-size: 200% !important;
  width: 100px;
  height: 100px;
  color: white !important;
  border: 2px solid white;
  border-radius: 50%;
  transition: 0.2s;
}

.nav-icon:hover{
  background: white;
  color: rgb(21,61,167) !important;
  border: 2px solid white;
}

#students-of-the-month{
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 75vh;
  background: rgb(21,61,167);
  color: white;
  width: 100vw;
  margin: auto;
  text-align: center;
}

.student-imgs{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80vw;
  margin: auto;
}

.featured-student{
  width: 200px;
  height: 200px;
  overflow: hidden;
  background: white;
  border: 2px solid black;
  border-radius: 50%;
  padding: 0;
}

.featured-student-img{
  width: 100%;
}

.carousel-home{
  height: 75vh;
  background: black;
  
}

.carousel-home-img{
  margin: auto;
  height: 75vh;
}

#join, #featured{
  min-height: 60vh;
  background: white;
}

.featured-content{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80vw;
  margin: auto;
}

.featured-col-left{
  width: 35vw;
}

.featured-col-left img {
  width: 50%;
}

.featured-col-right{
  width: 50vw;
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.join-link{
  color: rgb(21,61,167);
}

.join-link:hover{
  color: gold;
}

#sponsors{
  position: relative;
  min-height: 75vh;
  background: rgb(21,61,167);
  color: white;
  padding-top: 5vh;
}

.sponsor-img{
  width: 100%;
}

.sponsor-div{
  padding-left: 2vw;
  padding-right: 2vw;
}

.all-sponsors{
  display: flex;
  justify-content: center;
}

.sponsor-category{
  font-size: 175%;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.sponsors-1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  animation: sponsors1 10s infinite;
}

@keyframes sponsors1 {
  0% {
      opacity: 0;
  }

  5% {
      opacity: 1;
  }

  45% {
      opacity: 1;
  }

  50% {
      opacity: 0;
  }

  100% {
      opacity: 0;
  }
}

.sponsors-2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  animation: sponsors2 10s infinite;
}

@keyframes sponsors2 {
  0% {
      opacity: 0;
  }

  50% {
      opacity: 0;
  }

  55% {
      opacity: 1;
  }

  95% {
      opacity: 1;
  }

  100% {
      opacity: 0;
  }
}

@media (max-width: 1024px) {
  .nav-icons{
    align-items: normal;
    padding-top: 10vh;
  }

  .hero-image-div{
    left: 5%;
  }

  .hero-image {
      width: 40vw !important;
  }

  .featured-col-left img {
    width: 75%;
  }
}



@media (max-width: 768px) {


  .hero-box {
      width: 40vw;
  }

  .nav-icon{
    font-size: 150% !important;
  }

  .featured-content{
    display: block;
  }

  .featured-col-left{
    width: 80%;
    margin: auto;
    margin-top: 5vh;
  }

  .featured-col-right{
    width: 80%;
    margin: auto;
    margin-bottom: 5vh;
  }

  p {
      font-size: medium;
  }

  .carousel-home{
    overflow: hidden;
  }

  .carousel-home-img{
    object-fit: cover;
    width: 100%;
  }

}

@media (max-width: 425px) {
  .home-hero, #nav-icons, #join, #sponsors{
    min-height: 100vh;
  }

  .hero-image-div{
    display: none;
  }

  .hero-box {
    left: 0;
    width: 100vw;
  }

  .hero-title {
    font-size: 8vw;
    width: 100%;
    color: black;
    background: rgba(0, 0, 0, 0);
  }

  .hero-subtitle {
    font-size: 6vw;
    width: 150%;
    color: black;
  }
  
  .nav-icon{
    width: 80px;
    height: 80px;
  }

  .featured-content{
    display: block;
  }

  .featured-col-left{
    width: 100%;
    margin: auto;
    margin-top: 5vh;
  }

  .featured-col-left img {
    width: 75% !important;
  }

  .featured-col-right{
    width: 80%;
    margin: auto;
    margin-bottom: 5vh;
  }

  .featured-student{
    margin-bottom: 5vh;
  }

  .carousel-home{
    height: 100vh;
  }

  .carousel-home-img{
    height: 100vh;
  }

  .all-sponsors{
    flex-wrap: wrap;
    align-items: center;
  }

  .sponsor-div{
    width: 40vw;

  }

  .sponsor-heading{
    padding-top: 10vh !important;
  }

  .sponsor-category{
    margin-top: 10vh;
  }
  

  h2, h3 {
      font-size: 1em;
  }

  h4,
  h5 {
      font-size: .75em;
  }


}

@media (max-width: 375px) {
  .nav-icon{
    width: 70px;
    height: 70px;
  }
}


/* -------------------ENSEMBLE PAGES---------------------------- */

.ensemble-hero-container{
  position: relative;
  height: 40vh;
  background: white;
  overflow: hidden;
  mask-image: linear-gradient(to top, transparent 0%, black 5%);
}

.ensemble-banner{
  object-fit: cover;
  opacity: .2;
}

.ensemble-banner-done{
  opacity: 0.8;
  object-fit: cover;
  filter: blur(3px)
}

.ensemble-hero-title {
  font-size: 6vw;
  color: white;
  background: rgba(0, 0, 0, 0);
}

.ensemble-hero-title-black{
  font-size: 6vw;
  color: rgb(21,61,167);
  background: rgba(0, 0, 0, 0);
}

.ensemble-column-1{
  width: 50%;
}

.ensemble-column-2{
  width: 40%;
  display: flex;
  align-items: center;
  margin: auto;
}

.ensemble-hero-box {
  text-align: center;
  position: absolute;
  background: rgba(0, 0, 0, 0);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
  margin: auto;
}

.ensemble-info{
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-top: 5vh !important;
}

.ensemble-info-paragraph{
  text-align: center;
  margin-bottom: 5vh;
}

.ensemble-img{
  width: 100%;
}

.ensemble-btns{
  display: flex;
  margin-top: 5vh;
  margin-bottom: 10vh;
  justify-content: space-around;
}

.ensemble-sponsor-div{
  padding-left: 2vw;
  padding-right: 2vw;
  width: 20vw;
}

.ensemble-sponsors, .WGI-Banners{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
}

.ensemble-WGI-banner-div{
  width: 25vw;
}

.carousel-ensemble {
  min-height: 50vh;
  height: fit-content;
  margin-bottom: 10vh;
}

.ensemble-itinerary-btn-div{
  display: flex;
  justify-content: space-around;
  margin-bottom: 10vh !important;
}

.ensemble-awards{
  min-height: 60vh;
  color: white;
  background: rgb(21,61,167);
}

.gold-rating{
  color: gold;
}

@media (max-width: 1024px) {
  .ensemble-column-1{
    width: 100%;
  }
  
  .ensemble-column-2{
    width: 80%;
  }
}

@media (max-width: 425px) {
  .ensemble-hero-title{
    font-size: 12vw;
  }

  .ensemble-hero-title-black{
    font-size: 12vw;
  }

  .ensemble-column-2{
    width: 100%;
  }

  .ensemble-itinerary-btn-div{
    display: block;
  }

  .ensemble-itinerary-btn{
    margin-bottom: 5vh;
  }

  .ensemble-btns{
    display: block;
    text-align: center;
  }

  .WGI-Banners, .ensemble-sponsors{
    display: flex;
    justify-content: center !important;
    flex-wrap: wrap;
  }

  .ensemble-WGI-banner-div{
    width: 80vw;
    margin-top: 2vh;
  }

  .ensemble-sponsor-div{
    width: 40vw;
  }
}

/* -----------------CALENDAR--------------------------- */

iframe {
  z-index: 10;
  width: 80vw !important;
  height: 80vh !important;
  animation: calendar 2s;
  margin-top: 10vh !important;
  margin-bottom: 2vh;
  background-color: black;
  padding: 0 !important;
  animation: calendar 2s
}

@keyframes calendar {
  0% {
      opacity: 0;
  }

  60% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

/* --------------------STORE------------------------- */

.store-info{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  min-height: 30vh;
  width: 80vw;
  text-align: center;
  color: rgb(21,61,167);
}

.store-1{
  text-align: center;
  padding-top: 5vh;
  padding-bottom: 5vh;
  min-height: fit-content;
  background: rgb(21,61,167);
  color: white;
}

.store-2{
  text-align: center;
  padding-top: 5vh;
  padding-bottom: 5vh;
  min-height: fit-content;
  background: white;
  color: rgb(21,61,167);
}

.store-img-container{
  width: 80vw;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}

.store-img-div{
  width: 20vw;
  max-height: 60vh;
  margin-left: 2vw;
  margin-right: 2vw;
  margin-top: 2vh;
  margin-bottom: 2vh;
  overflow: scroll;
}

.store-img-div-grow{
  position: fixed;
  display: flex;
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  height: 100vh;
  overflow: scroll;
  margin: auto;
  z-index: 100;
  top: 0;
  border: 5vw solid rgba(0, 0, 0, 0);
}

.store-img{
  width: 100%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .store-img-div{
    width: 35vw;
  }
}

@media (max-width: 425px) {
  .store-img-div{
    width: 80vw;
  }
}

/* --------------------GET INVOLVED------------------- */

.getinvolved-gold{
  color: gold;
}

.getinvolved-blue{
  color: rgb(21,61,167);
}

.getinvolved-section2{
  background: rgb(21,61,167);
  color: white;
}

/* ------------------FEES PAGE------------------------- */
.other-hero-container{
  position: relative;
  height: 40vh;
  background: white;
  color: rgb(21,61,167);
  overflow: hidden;
  mask-image: linear-gradient(to top, transparent 0%, black 5%);
}

.other-banner{
  opacity: 0.6;
}

.other-hero-title {
  font-size: 6vw;
  color: rgb(21,61,167);
  background: rgba(0, 0, 0, 0);
}

.other-hero-box {
  text-align: center;
  position: absolute;
  background: rgba(0, 0, 0, 0);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
  margin: auto;
}

.fees-info{
  background: rgb(21,61,167);
  min-height: 80vh;
  color: white;
}

.btn-fees{
  font-size: 2.5rem;
  background: gold;
  color: black;
}

.musicoffice-link{
  color: gold;
}

.musicoffice-link:hover{
  color: rgb(196, 166, 0);
}

.buttons{
  display: flex;
  justify-content: space-around;
}

@media (max-width: 425px) {
  .other-hero-title{
    font-size: 10vw;
  }

  .fees-info{
    padding-top: 5vh;
    padding-bottom: 5vh;
  }

  .buttons{
    flex-direction: column;
    justify-content: center;
  }

  .btn{
    margin-top: 2vh;
    width: 50vw;
    margin-left: auto;
    margin-right: auto;
  }
}

/* ------------------STAFF PAGE------------------------ */
.staff-hero-container{
  position: relative;
  height: 40vh;
  color: rgb(21,61,167) !important;
  overflow: hidden;
  mask-image: linear-gradient(to top, transparent 0%, black 5%);
}

.staff-hero-title {
  font-size: 6vw;
  color: rgb(21,61,167);
  background: rgba(0, 0, 0, 0);
}

.headshot-div{
  width: 20vw;
}

.staff-info{
  width: 50vw;
}

.headshot-staff{
  width: 100% !important;
  border-radius: 50%;
  box-shadow: 0 2px 8px 0 rgba(21, 61, 167, 0.779), 0 6px 30px 0 rgba(21, 61, 167, 0.779);
}

.headshot-staff2{
  width: 100% !important;
  background: white;
  border-radius: 50%;
  box-shadow: 0 0px 0px 0 rgba(255, 255, 255, 0.779), 0 0px 30px 0 rgba(255, 255, 255, 0.779);
}

.staff1{
  background: white;
  color: black
}

.staff2{
  background: rgb(21,61,167);
  color: white;
}

.boosters{
  min-height: 50vh;
  background: white;
  color: rgb(21,61,167);
}

.offcanvas {
  height: 70vh;
}

.offcanvas-last {
  padding-bottom: 20px !important;
}

.offcanvas-header, .offcanvas-body{
  color: black;
}

@media (max-width: 425px) {
  .headshot-div{
    width: 40vw;
  }

  .bio-btn{
    width: 40vw;
  }
}

/* -------------------------LINKS------------------------------ */


.links-info{
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 5vh;
  padding-bottom: 5vh;
  background: rgb(21,61,167);
  color: white;
  min-height: 80vh;
  text-align: center; 
}

.links-link{
  text-decoration: none;
  color: white;
}

.links-link:hover{
  color: gold;
}

@media (max-width: 768px) {
  .links-info{
    display: block;
  }

  .link-column-1{
    width: 100vw;
    margin-bottom: 10vh;
  }

  .link-column-2{
    width: 100vw;
  }
}


/* --------------------------FOOTER---------------------------- */

.footer{
  width: 100vw;
  height: 40vh;
  margin: 0;
  background-color: rgb(80, 80, 80);
  color: white;
}

.footer-link{
  color: white;
  text-decoration: none;
}

.footer-link:hover{
  color: gold;
}

.fa-brands{
  font-size: 200% !important;
  color: white !important;
}

.fa-brands:hover{
  color: gold !important;
}

@media (max-width: 425px) {
  
  .footer-col-1, .footer-col-2{
    width: 40vw;
    font-size: 80%;
    margin: auto;
  }

  .footer-col-1 h1{
    font-size: 120%;
  }
}


/* --------------------------ADMIN------------------------- */

.admin{
  text-align: center;
}

.admin-hero-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  background: rgba(0, 0, 0, 0);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  margin: auto;
  padding: 0;
}

.admin-hero-title{
  font-size: 5vw;
}

.upload{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: auto;
  margin-bottom: 5vh;
}

.admin-col{
  display: flex;
  flex-direction: column;
  width: 30vw;
  margin-top: 5vh;
}

.admin-img{
  display: flex;
  justify-content: space-around;
}

@media (max-width: 425px) {
  .admin-col{
    display: block;
    width: 80vw !important;
  }

  .admin-hero-title{
    font-size: 15vw;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .admin-col{
    display: block;
    width: 40vw;
  }
}



